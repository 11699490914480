import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/Hero';

const NotFoundPage = () => (
	<Layout>
		<Hero />
		<section>
			<div className="container">
				<div className="section has-text-centered">
					<h1>Error 404</h1>
					<p>Sidan kan inte hittas</p>
				</div>
			</div>
		</section>
	</Layout>
);

export default NotFoundPage;
